import React, { useCallback, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AgricultureIcon from '@mui/icons-material/Agriculture';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import HideSourceIcon from '@mui/icons-material/HideSource';
import HistoryIcon from '@mui/icons-material/History';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IgnoreIcon from '@mui/icons-material/LayersClear';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PendingIcon from '@mui/icons-material/PendingActions';
import RefreshIcon from '@mui/icons-material/Refresh';
import ProcessedIcon from '@mui/icons-material/Verified';
import {
	Chip,
	CircularProgress,
	Collapse,
	Divider,
	Fab,
	IconButton,
	Link,
	ListItemIcon,
	Menu,
	MenuItem,
	Paper,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { differenceInMinutes, format, startOfDay } from 'date-fns';
import PropTypes from 'prop-types';

import {
	AdminService,
	CompanyService,
	InvoiceService,
	UserService,
} from '@asteria/backend-utils-services';

import { selectToken } from '../../../../store/auth';
import { PRINTER_URL } from '../../../../utils/configuration';
import InvoiceFilters from '../../components/Filters';
import InvoiceSearch from '../../components/Search';
import { InvoicesContext } from '../../context';

import './styles.scss';

const getStatusIcon = (status) => {
	if (status === 'PROCESSED') {
		return <ProcessedIcon />;
	}

	if (status === 'PENDING') {
		return <PendingIcon />;
	}

	if (status === 'IGNORED') {
		return <IgnoreIcon />;
	}

	return <PendingIcon />;
};

const InvoiceTableRow = ({
	row,
	onIgnore,
	onRevert,
	onProcessed,
	onEdit,
	onDelete,
	onAutomate,
	onReadData,
}) => {
	const anchorRef = useRef(null);
	const [showActionsMenu, setActionsMenu] = useState(false);

	const toggleActionsMenu = useCallback(() => {
		setActionsMenu(!showActionsMenu);
	}, [showActionsMenu]);

	const edit = useCallback(
		(cb) => {
			setActionsMenu(false);
			onEdit(row);
		},
		[row, onEdit],
	);

	const revert = useCallback(
		(cb) => {
			setActionsMenu(false);
			onRevert(row);
		},
		[row, onRevert],
	);

	const process = useCallback(
		(cb) => {
			setActionsMenu(false);
			onProcessed(row);
		},
		[row, onProcessed],
	);

	const ignore = useCallback(
		(cb) => {
			setActionsMenu(false);
			onIgnore(row);
		},
		[row, onIgnore],
	);

	const deleteIt = useCallback(
		(cb) => {
			setActionsMenu(false);
			onDelete(row);
		},
		[row, onDelete],
	);

	const automateIt = useCallback(
		(cb) => {
			setActionsMenu(false);
			onAutomate(row);
		},
		[row, onAutomate],
	);

	const readData = useCallback(() => {
		setActionsMenu(false);
		onReadData(row);
	}, [row, onReadData]);

	return (
		<TableRow>
			<TableCell component="th" scope="row">
				{row?.id} - {row?.data?.version?.major}
			</TableCell>
			<TableCell align="right">
				{row?.invoices
					?.map(({ meta: { invoiceNumber } = {} }) => invoiceNumber)
					.join(', ')}
			</TableCell>
			<TableCell align="right">
				{format(new Date(row.createdAt), 'yyyy-MM-dd HH:mm')}
			</TableCell>
			<TableCell align="right">{getStatusIcon(row.status)}</TableCell>
			<TableCell align="right">{row?.layout?.error ?? ''}</TableCell>
			<TableCell align="right">
				{differenceInMinutes(
					new Date(row?.invoices?.[0]?.createdAt || new Date()),
					new Date(row.createdAt),
				)}
			</TableCell>
			<TableCell align="right">
				<IconButton
					ref={anchorRef}
					onClick={toggleActionsMenu}
					size="large"
				>
					<MoreVertIcon />
				</IconButton>
				<Menu
					anchorEl={anchorRef.current}
					open={showActionsMenu}
					onClose={() => setActionsMenu(false)}
				>
					<MenuItem onClick={edit}>
						<ListItemIcon>
							<EditIcon />
						</ListItemIcon>
						Edit
					</MenuItem>
					<MenuItem
						as="a"
						target="__blank"
						href={`/invoices/layout/${row.id}`}
					>
						<ListItemIcon>
							<EditIcon />
						</ListItemIcon>
						Edit in new tab
					</MenuItem>
					<MenuItem onClick={automateIt}>
						<ListItemIcon>
							<AgricultureIcon />
						</ListItemIcon>
						Run
					</MenuItem>
					<MenuItem onClick={readData}>
						<ListItemIcon>
							<AgricultureIcon />
						</ListItemIcon>
						Read Data
					</MenuItem>

					<MenuItem>
						<Link
							onClick={toggleActionsMenu}
							href={`${PRINTER_URL}invoices/pdf/${row.pdfUri
								.replace('.json', '.pdf')
								.replace('_ocr', '')}`}
							target="__blank"
						>
							Download PDF
						</Link>
					</MenuItem>
					<Divider />
					{row.status !== 'IGNORED' ? (
						<MenuItem onClick={ignore}>
							<ListItemIcon>
								<HideSourceIcon fontSize="small" />
							</ListItemIcon>
							Ignore
						</MenuItem>
					) : null}
					{row.status !== 'PENDING' ? (
						<MenuItem onClick={revert}>
							<ListItemIcon>
								<HistoryIcon />
							</ListItemIcon>
							Revert
						</MenuItem>
					) : null}
					{row.status !== 'PROCESSED' ? (
						<MenuItem onClick={process}>
							<ListItemIcon>
								<DoneIcon />
							</ListItemIcon>
							Processed
						</MenuItem>
					) : null}
					<Divider />
					<MenuItem onClick={deleteIt}>
						<ListItemIcon>
							<DeleteIcon />
						</ListItemIcon>
						Delete
					</MenuItem>
				</Menu>
			</TableCell>
		</TableRow>
	);
};

const last = (items) => items[items.length - 1];

const LayoutTableRow = ({
	row,
	onAutomateAll,
	onReprocessAll,
	onIgnoreAll,
	...props
}) => {
	const [open, setOpen] = useState(false);
	const { onEdit } = props;
	const anchorRef = useRef(null);
	const [showActionsMenu, setActionsMenu] = useState(false);

	const toggleActionsMenu = useCallback(() => {
		setActionsMenu(!showActionsMenu);
	}, [showActionsMenu]);

	const edit = useCallback(() => {
		onEdit(row.items[0]);
	}, [row.items, onEdit]);

	const onAutomate = useCallback(() => {
		onAutomateAll(row);
	}, [onAutomateAll, row]);

	const onReprocess = useCallback(() => {
		onReprocessAll(row);
	}, [onReprocessAll, row]);

	const onIgnore = useCallback(() => {
		onIgnoreAll(row);
	}, [onIgnoreAll, row]);

	return (
		<>
			<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? (
							<KeyboardArrowUpIcon />
						) : (
							<KeyboardArrowDownIcon />
						)}
					</IconButton>
				</TableCell>
				<TableCell>{row?.items?.length}</TableCell>
				<TableCell>
					{row?.company?.name} ({row?.company?.state})
				</TableCell>
				<TableCell component="th" scope="row">
					{row?.company?.id}
				</TableCell>
				<TableCell component="th" scope="row">
					{format(
						new Date(last(row.items).createdAt),
						'yyyy-MM-dd HH:mm',
					)}
				</TableCell>
				<IconButton
					ref={anchorRef}
					onClick={toggleActionsMenu}
					size="large"
				>
					<MoreVertIcon />
				</IconButton>
				<Menu
					anchorEl={anchorRef.current}
					open={showActionsMenu}
					onClose={() => setActionsMenu(false)}
				>
					<MenuItem onClick={edit}>
						<ListItemIcon>
							<EditIcon />
						</ListItemIcon>
						Edit
					</MenuItem>
					<MenuItem onClick={onAutomate}>
						<ListItemIcon>
							<EditIcon />
						</ListItemIcon>
						Automate
					</MenuItem>
					<MenuItem onClick={onReprocess}>
						<ListItemIcon>
							<EditIcon />
						</ListItemIcon>
						Reprocess
					</MenuItem>
					<MenuItem onClick={onIgnore}>
						<ListItemIcon>
							<EditIcon />
						</ListItemIcon>
						Ignore
					</MenuItem>
				</Menu>
			</TableRow>
			<TableRow>
				<TableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={6}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1 }}>
							<Typography
								variant="h6"
								gutterBottom
								component="div"
							>
								Invoices
							</Typography>
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell>ID</TableCell>
										<TableCell>Invoice Id's</TableCell>
										<TableCell align="right">
											Created
										</TableCell>
										<TableCell align="right">
											Status
										</TableCell>
										<TableCell align="right">
											Error
										</TableCell>
										<TableCell align="right">
											Delay
										</TableCell>
										<TableCell align="right">
											Edit
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{row.items.map((invoiceRow) => (
										<InvoiceTableRow
											key={invoiceRow.id}
											{...props}
											row={invoiceRow}
										/>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);

	/*
	return (
		
	);
	*/
};

const CompaniesLayoutTab = (props) => {
	const { onAction } = React.useContext(InvoicesContext);
	const accessToken = useSelector(selectToken);
	const navigate = useNavigate();

	const [filter, setFilter] = React.useState({
		status: 'PENDING',
		startDate: localStorage.invoiceFilerDate
			? new Date(localStorage.invoiceFilerDate)
			: startOfDay(new Date()),
		clientStage: 'APPROVED',
	});
	const [search, setSearch] = React.useState('');

	const {
		data: layouts = [],
		isLoading,
		isFetching,
	} = useQuery({
		queryKey: [
			'layouts',
			filter?.status !== 'ALL' ? filter?.status : null,
			filter?.startDate,
		],
		queryFn: () =>
			InvoiceService.invoice.extension
				.invoiceLayouts(
					{
						status:
							filter?.status !== 'ALL' ? filter?.status : null,
						startDate: filter?.startDate
							? `${format(
								filter?.startDate,
								'yyyy-MM-dd',
							  )}T00:00:00.000`
							: null,
						endDate: filter?.startDate
							? `${format(new Date(), 'yyyy-MM-dd')}T23:59:59.999`
							: null,
					},
					{ token: accessToken },
				)
				.then(({ edges }) => edges.map(({ node }) => node))
				.then((response) =>
					response.sort(
						(a, b) => new Date(a.createdAt) - new Date(b.createdAt),
					),
				),
	});

	const { data: pendingLayouts = [] } = useQuery({
		queryKey: ['layouts', 'pending'],
		refetchInterval: 1000 * 15,
		refetchIntervalInBackground: true,
		queryFn: () =>
			InvoiceService.invoice.extension
				.invoiceLayouts(
					{
						status: ['PENDING', 'ERROR'],
						startDate: `${format(
							new Date(),
							'yyyy-MM-dd',
						)}T00:00:00.000`,
						endDate: `${format(
							new Date(),
							'yyyy-MM-dd',
						)}T23:59:59.999`,
					},
					{ token: accessToken },
				)
				.then(({ edges }) => edges.map(({ node }) => node))
				.then((response) =>
					response.sort(
						(a, b) => new Date(a.createdAt) - new Date(b.createdAt),
					),
				),
	});

	const queryClient = useQueryClient();

	const fetchLayouts = React.useCallback(
		async (reset = true) => {
			queryClient.invalidateQueries('layouts');
		},
		[queryClient],
	);

	React.useEffect(() => {
		fetchLayouts();

		if (
			Notification.permission !== 'granted' &&
			Notification.permission !== 'denied'
		) {
			Notification.requestPermission(() => {
				console.log('Granted');
			});
		}
	}, [fetchLayouts]);

	const notification = useRef(null);

	React.useEffect(() => {
		const onVisibilityChange = () => {
			if (!document.hidden && notification.current) {
				notification.current.close();
			}
		};
		document.addEventListener('visibilitychange', onVisibilityChange);

		return () => {
			document.removeEventListener(
				'visibilitychange',
				onVisibilityChange,
			);
		};
	}, []);

	React.useEffect(() => {
		if (!document.hidden) {
			return;
		}

		if (pendingLayouts.length && Notification.permission === 'granted') {
			if (notification.current) {
				notification.current.close();
			}

			notification.current = new Notification(
				`You have ${pendingLayouts.length} new prints to process`,
			);

			const currentNotification = notification.current;
			currentNotification.addEventListener('close', (event) => {
				if (notification.current === currentNotification) {
					notification.current = null;
				}
			});
		}
	}, [pendingLayouts]);

	const filteredLayouts = React.useMemo(() => {
		return layouts.filter((item) => {
			if (item?.company?.name && item.company.name.includes(search)) {
				return true;
			}

			if (
				item?.invoices &&
				item?.invoices?.length > 0 &&
				item.invoices.some((invoice) => {
					return invoice.meta.invoiceNumber.includes(search);
				})
			) {
				return true;
			}

			return false;
		});
	}, [layouts, search]);

	const grouppedByCompany = React.useMemo(() => {
		return Object.values(
			filteredLayouts.reduce((acc, item) => {
				if (!acc[item.company.id]) {
					acc[item.company.id] = { company: item.company, items: [] };
				}

				acc[item.company.id].items.push(item);

				return acc;
			}, {}),
		);
	}, [filteredLayouts]);

	const counts = React.useMemo(() => {
		return grouppedByCompany.reduce((acc, item) => {
			if (!acc[item?.company?.state]) {
				acc[item?.company?.state] = 0;
			}

			acc[item?.company?.state] += item?.items?.length || 0;

			return acc;
		}, {});
	}, [grouppedByCompany]);

	const items = React.useMemo(() => {
		return grouppedByCompany.filter(
			(item) =>
				item?.company?.state === filter?.clientStage ||
				filter?.clientStage === '*' ||
				filter?.status !== 'PENDING',
		);
	}, [grouppedByCompany, filter?.clientStage, filter?.status]);

	const onEdit = React.useCallback(
		({ id }) => {
			navigate(`/invoices/layout/${id}`);
		},
		[navigate],
	);

	const onIgnore = React.useCallback(
		async ({ id }) => {
			await InvoiceService.invoice.extension.updateLayout({
				id: id,
				status: 'IGNORED',
			});

			fetchLayouts(false);
		},
		[fetchLayouts],
	);

	const onIgnoreAll = React.useCallback(
		async (rows) => {
			for (let i = 0; i < rows.items.length; i += 1) {
				const { id } = rows.items[i];
				await InvoiceService.invoice.extension.updateLayout({
					id: id,
					status: 'IGNORED',
				});
			}
			fetchLayouts(false);
		},
		[fetchLayouts],
	);

	const onRevert = React.useCallback(
		async ({ id }) => {
			await InvoiceService.invoice.extension.updateLayout({
				id: id,
				status: 'PENDING',
			});

			fetchLayouts(false);
		},
		[fetchLayouts],
	);

	const onProcessed = React.useCallback(
		async ({ id }) => {
			await InvoiceService.invoice.extension.updateLayout({
				id: id,
				status: 'PROCESSED',
			});

			fetchLayouts(false);
		},
		[fetchLayouts],
	);

	const onDelete = React.useCallback(
		async ({ id }) => {
			await InvoiceService.invoice.extension.deleteLayouts({
				ids: [id],
			});

			fetchLayouts(false);
		},
		[fetchLayouts],
	);

	const onAutomate = React.useCallback(
		async ({ id }) => {
			await AdminService.theme.extension.addProcessPrintJob({
				id: id,
			});

			await InvoiceService.invoice.extension.updateLayout({
				id: id,
				status: 'QUEUE',
			});

			fetchLayouts(false);
		},
		[fetchLayouts],
	);

	const onAutomateAll = React.useCallback(
		async (rows) => {
			for (let i = 0; i < rows.items.length; i += 1) {
				const { id } = rows.items[i];
				await AdminService.theme.extension.addProcessPrintJob({
					id: id,
				});

				await InvoiceService.invoice.extension.updateLayout({
					id: id,
					status: 'QUEUE',
				});
			}
			fetchLayouts(false);
		},
		[fetchLayouts],
	);

	const onReadData = React.useCallback(
		async (row, pdf = false) => {
			const {
				edges: [{ node: user }],
			} = await UserService.user.fetch({
				filters: { companyId: row.company.id },
			});

			let psFilePath = row.pdfUri
				.replace('_print.pdf', '.ps')
				.replace('.pdf', '.ps');

			console.log(pdf);
			if (pdf) {
				psFilePath = row.pdfUri;
			}

			const uri = `${PRINTER_URL}invoices/process/${row.company.id}/${user.id}/${row.id}/${psFilePath}`;

			// console.log(uri);
			fetch(uri);

			// fetchLayouts(false);
		},
		[fetchLayouts],
	);

	const onReprocess = React.useCallback(
		async (rows) => {
			const {
				edges: [{ node: user }],
			} = await UserService.user.fetch({
				filters: { companyId: rows.company.id },
			});

			for (let i = 0; i < rows.items.length; i += 1) {
				const row = rows.items[i];

				let psFilePath = row.pdfUri
					.replace('_print.pdf', '.ps')
					.replace('.pdf', '.ps');

				// if (pdf) {
				// 	psFilePath = row.pdfUri;
				// }

				const uri = `${PRINTER_URL}invoices/process/${rows.company.id}/${user.id}/${row.id}/${psFilePath}`;

				// console.log(uri);
				fetch(uri);
			}
			fetchLayouts(false);
		},
		[fetchLayouts],
	);

	const statusCounts = React.useMemo(() => {
		return pendingLayouts.reduce(
			(acc, item) => {
				acc[item.status] += 1;

				return acc;
			},
			{ PENDING: 0, ERROR: 0 },
		);
	}, [pendingLayouts]);

	return (
		<Paper
			elevation={0}
			sx={{
				flexGrow: 1,
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: 2,
				}}
			>
				<InvoiceFilters
					setFilter={setFilter}
					statusCounts={statusCounts}
					filters={filter}
				/>
				<InvoiceSearch setSearch={setSearch} />
				<IconButton onClick={fetchLayouts}>
					<RefreshIcon />
				</IconButton>
			</Box>
			{isFetching && (
				<Fab
					size="small"
					color="secondary"
					aria-label="add"
					sx={{ position: 'fixed', bottom: '10px', right: '10px' }}
				>
					<CircularProgress />
				</Fab>
			)}
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell />
							<TableCell>Invoices</TableCell>
							<TableCell>Company</TableCell>
							<TableCell>ID</TableCell>
							<TableCell>Latest</TableCell>
							<TableCell>Edit</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading ? (
							<TableRow>
								<TableCell colSpan="4">
									<Skeleton />
								</TableCell>
							</TableRow>
						) : null}
						{items.map((row) => (
							<LayoutTableRow
								key={row.company.id}
								row={row}
								onEdit={onEdit}
								onIgnore={onIgnore}
								onProcessed={onProcessed}
								onRevert={onRevert}
								onDelete={onDelete}
								onAutomate={onAutomate}
								onAutomateAll={onAutomateAll}
								onIgnoreAll={onIgnoreAll}
								onReadData={onReadData}
								onReprocessAll={onReprocess}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
};

CompaniesLayoutTab.displayName = 'CompaniesLayoutTab';

CompaniesLayoutTab.propTypes = { className: PropTypes.string };

export default CompaniesLayoutTab;
